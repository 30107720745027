@import 'animate.css';
@import 'normalize.css';

* {
  box-sizing: border-box;
}
body {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  background: linear-gradient(135deg, #e065fd, #7e00a5);
  color: #707070;
}
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}
#app {
  padding: 1em;
}
.messageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 42vw;
  min-width: 500px;

  > .message {
    box-shadow: 0 3px 70px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 30px;
    border: 2px solid #d6d6d6;
    background: #fff;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 150px;
    margin-right: 30px;
  }
  span {
    text-align: center;
    white-space: pre-wrap;
    font-size: 25px;
    width: 90%;
  }
}

.sidebar {
  width: 23vw;
  min-width: 300px;
  height: 100%;
  position: fixed;
  right: calc(-23vw - 50px);
  top: 20px;
  transition: all 0.3s ease-in-out;

  &.open {
    right: 20px;
  }
}
.messageList {
  height: 100%;
  overflow-y: auto;
  .message {
    position: relative;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #d6d6d6;
    background: #fff;

    margin-bottom: 18px;

    height: 72px;
    display: flex;
    align-items: center;

    .icon {
      width: 36px;
      margin-right: 16px;
    }
    span {
      text-align: center;
      white-space: pre-wrap;
      font-size: 13px;
    }
    .date {
      position: absolute;
      bottom: 4px;
      width: 100%;

      font-size: 8px;
      font-weight: 700;
      text-align: center;

      opacity: 0;
    }
    &:hover {
      .date {
        opacity: 1;
      }
    }
  }
}

.plainButton {
  background-color: transparent;
  border: 0;
  img {
    width: 50px;
    height: auto;
  }
}
#openMessagesButton {
  right: calc(25vw + 50px);
  bottom: 40px;
  position: absolute;

  @extend .plainButton;
}

#toggleConnectButton {
  position: absolute;
  left: 20px;
  bottom: 20px;
  @extend .plainButton;
}
